export default {
  paddingtype: {
    label: "Padding Type",
    checkbox: null,
    field: ["Default", "Remove Top Padding", "Remove Bottom Padding"],
    selected: "Default",
  },
  headingstyle: {
    label: "Heading Style",
    checkbox: null,
    field: ["Content", "Heading", "None"],
    selected: "None",
  },
  numColumns: {
    label: "Number of Columns",
    checkbox: null,
    field: [1, 2],
    selected: 2,
  },
  enableheading: {
    label: "Enable Feature Title",
    checkbox: true,
    field: null,
  },
  enablecopy: {
    label: "Enable Feature Copy",
    checkbox: true,
    field: null,
  },
  largeicons: {
    label: "Enable Large Icons",
    checkbox: false,
    field: null,
  },
  headingmode: {
    label: "Heading Mode",
    checkbox: null,
    field: ["H2", "H3", "H4", "H5", "H6", "SPAN"],
    selected: "H4",
  },
  kflite1: [
    {
      label: "Icon 1",
      checkbox: null,
      field: "#iphone-ipad",
      block: 1,
    },
    {
      label: "Feature Title 1",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 1",
        Span: "Título 1",
        ChS: "标题 1",
        Gr: "Überschrift 1",
        Ru: "Заголовок 1",
        Fr: "Titre 1",
        It: "Titolo 1",
        Pl: "Tytuł 1",
        Port: "Título 1",
        Viet: "Cú đội đầu 1",
        Turk: "Başlık 1",
        Ukrn: "Заголовок 1",
        Thai: "หัวเรื่อง 1",
        Kor: "표제 1",
        ChT: "標題 1",
        Jp: "見出し 1",
      },
      block: 1,
    },
    {
      label: "Feature Copy 1",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 1,
    },
  ],

  kflite2: [
    {
      label: "Icon 2",
      checkbox: null,
      field: "#iphone-ipad",
      block: 2,
    },
    {
      label: "Feature Title 2",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 2",
        Span: "Título 2",
        ChS: "标题 2",
        Gr: "Überschrift 2",
        Ru: "Заголовок 2",
        Fr: "Titre 2",
        It: "Titolo 2",
        Pl: "Tytuł 2",
        Port: "Título 2",
        Viet: "Cú đội đầu 2",
        Turk: "Başlık 2",
        Ukrn: "Заголовок 2",
        Thai: "หัวเรื่อง 2",
        Kor: "표제 2",
        ChT: "標題 2",
        Jp: "見出し 2",
      },
      block: 2,
    },
    {
      label: "Feature Copy 2",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 2,
    },
  ],

  kflite3: [
    {
      label: "Icon 3",
      checkbox: null,
      field: "#iphone-ipad",
      block: 3,
    },
    {
      name: "heading3",
      label: "Feature Title 3",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 3",
        Span: "Título 3",
        ChS: "标题 3",
        Gr: "Überschrift 3",
        Ru: "Заголовок 3",
        Fr: "Titre 3",
        It: "Titolo 3",
        Pl: "Tytuł 3",
        Port: "Título 3",
        Viet: "Cú đội đầu 3",
        Turk: "Başlık 3",
        Ukrn: "Заголовок 3",
        Thai: "หัวเรื่อง 3",
        Kor: "표제 3",
        ChT: "標題 3",
        Jp: "見出し 3",
      },
      block: 3,
    },
    {
      label: "Feature Copy 3",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 3,
    },
  ],

  kflite4: [
    {
      label: "Icon 4",
      checkbox: null,
      field: "#iphone-ipad",
      block: 4,
    },
    {
      label: "Feature Title 4",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 4 Example dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Ejemplo de encabezado de función con un encabezado muy largo que empujará hacia abajo cualquier texto en el contenido a continuación, y sigue y sigue y es realmente más largo que un título normal.",
        ChS: "长的标题的示例，该标题会下推下面内容中的任何文本，并且它会一直持续下去，实际上比普通标题更长。",
        Gr: "Feature-Überschrift 4 Beispiel mit einer sehr langen Überschrift, die jeglichen Text im Inhalt unten nach unten drängt, und sie geht immer weiter und ist tatsächlich länger als ein normaler Titel.",
        Ru: "Заголовок функции 4 Пример с очень длинным заголовком, который будет смещать любой текст в содержимом ниже, и он продолжается и продолжается и на самом деле длиннее, чем обычный заголовок.",
        Fr: "Lorem Ipsum 4 Exemple avec un très long en-tête qui poussera tout texte dans le contenu ci-dessous, et il continue encore et encore et est en fait plus long qu'un titre normal.",
        It: "Feature Titolo 4 Esempio con un'intestazione molto lunga che spingerà verso il basso qualsiasi testo nel contenuto sottostante, e continua all'infinito ed è in realtà più lunga di un titolo normale.",
        Pl: "Nagłówek funkcji 4 Przykład z bardzo długim nagłówkiem, który przesunie dowolny tekst w treści poniżej, ciągnie się i trwa i jest faktycznie dłuższy niż normalny tytuł.",
        Port: "Título de recurso 4 Exemplo com um cabeçalho muito longo que empurrará para baixo qualquer texto no conteúdo abaixo, e continua indefinidamente e é realmente mais longo do que um título normal.",
        Viet: "Ví dụ về Tiêu đề Đặc điểm 4 với một tiêu đề rất dài sẽ đẩy bất kỳ văn bản nào trong nội dung bên dưới xuống, và nó cứ lặp đi lặp lại và thực sự dài hơn một tiêu đề bình thường.1",
        Turk: "Özellik Başlığı 4 Aşağıdaki içerikteki herhangi bir metni aşağı itecek çok uzun bir başlığa sahip örnek ve uzayıp gidiyor ve aslında normal bir başlıktan daha uzun.",
        Ukrn: "Функція Заголовок 4. Приклад із дуже довгим заголовком, який виштовхує будь-який текст у вмісті нижче, і він продовжується і продовжується, і фактично довший за звичайний заголовок.",
        Thai: "ส่วนหัวของคุณลักษณะ 4 ตัวอย่างที่มีส่วนหัวที่ยาวมากซึ่งจะดันข้อความใดๆ ในเนื้อหาด้านล่างลงไป และมันจะยาวขึ้นเรื่อยๆ และจริงๆ แล้วยาวกว่าชื่อเรื่องปกติ",
        Kor: "Lorem Ipsum 4 아래 콘텐츠의 모든 텍스트를 아래로 밀어내는 매우 긴 헤더가 있는 예이며, 계속해서 진행되며 실제로 일반 제목보다 깁니다.",
        ChT: "功能標題 4 具有非常長的標題的示例，該標題會下推下面內容中的任何文本，並且它會一直持續下去，實際上比普通標題更長。",
        Jp: "機能見出し 4 以下のコンテンツ内のテキストを押し下げる非常に長いヘッダーの例。ヘッダーは延々と続き、実際には通常のタイトルよりも長くなっています。",
      },
      block: 4,
    },
    {
      label: "Feature Copy 4",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 4,
    },
  ],

  kflite5: [
    {
      label: "Icon 5",
      checkbox: null,
      field: "#iphone-ipad",
      block: 5,
    },
    {
      label: "Feature Title 5",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 5",
        Span: "Título 5",
        ChS: "标题 5",
        Gr: "Überschrift 5",
        Ru: "Заголовок 5",
        Fr: "Titre 5",
        It: "Titolo 5",
        Pl: "Tytuł 5",
        Port: "Título 5",
        Viet: "Cú đội đầu 5",
        Turk: "Başlık 5",
        Ukrn: "Заголовок 5",
        Thai: "หัวเรื่อง 5",
        Kor: "표제 5",
        ChT: "標題 5",
        Jp: "見出し 5",
      },
      block: 5,
    },
    {
      label: "Feature Copy 5",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 5,
    },
  ],

  kflite6: [
    {
      label: "Icon 6",
      checkbox: null,
      field: "#iphone-ipad",
      block: 6,
    },
    {
      label: "Feature Title 6",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 6",
        Span: "Título 6",
        ChS: "标题 6",
        Gr: "Überschrift 6",
        Ru: "Заголовок 6",
        Fr: "Titre 6",
        It: "Titolo 6",
        Pl: "Tytuł 6",
        Port: "Título 6",
        Viet: "Cú đội đầu 6",
        Turk: "Başlık 6",
        Ukrn: "Заголовок 6",
        Thai: "หัวเรื่อง 6",
        Kor: "표제 6",
        ChT: "標題 6",
        Jp: "見出し 6",
      },
      block: 6,
    },
    {
      label: "Feature Copy 6",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 6,
    },
  ],

  kflite7: [
    {
      label: "Icon 7",
      checkbox: null,
      field: "#iphone-ipad",
      block: 7,
    },
    {
      label: "Feature Title 7",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum 7",
        Span: "Título 7",
        ChS: "标题 7",
        Gr: "Überschrift 7",
        Ru: "Заголовок 7",
        Fr: "Titre 7",
        It: "Titolo 7",
        Pl: "Tytuł 7",
        Port: "Título 7",
        Viet: "Cú đội đầu 7",
        Turk: "Başlık 7",
        Ukrn: "Заголовок 7",
        Thai: "หัวเรื่อง 7",
        Kor: "표제 7",
        ChT: "標題 7",
        Jp: "見出し 7",
      },
      block: 7,
    },
    {
      label: "Feature Copy 7",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
        ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
        Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
        Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
        Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
        It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
        Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
        Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
        Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
        Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
        Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
        Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
        Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
        ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
        Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
      },
      block: 7,
    },
  ],
  currentLang: "Eng",
};
