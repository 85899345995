import React from "react";
import C_ColorSwatch from "../../../../2_components/colorSwatch/C_ColorSwatch";

let svgLogoSP1 = "https://www.kingstonloda.com/AssetLink/x5tbip4us06v8123xlbh64wp2g42e0ql.svg";
let svgLogoSP2 = "https://www.kingstonloda.com/AssetLink/ib0353ell6ril647071b881ds6yemvyv.svg";
let svgLogoSP3 = "https://www.kingstonloda.com/AssetLink/ep38ca77s864js7x44fpgx182l4f0088.svg";
let svgLogoSP4 = "https://www.kingstonloda.com/AssetLink/01050ww7bn8g1bqcg5137y0ne4o2bxlm.svg";

const ServerPremier = () => {
  return (
    <>
      <div className="s-content">
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>Server Premier Logo</h2>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The <strong>Server Premier®</strong> logo should always be used in proportion (as
                shown) and should not be changed or altered in any way. Kingston’s Server Premier®
                logo has two variations:
              </p>
              <ul>
                <li>
                  <strong>Full color Server Premier Logo</strong>
                </li>
                <li>
                  <strong>Monochromatic Server Premier Logo</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Full color - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={svgLogoSP1} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Full color - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={svgLogoSP2} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Monochromatic - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={svgLogoSP3} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Monochromatic - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={svgLogoSP4} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch
                    pms={"186C"}
                    hex={"#C8102E"}
                    cmyk={"0/100/80/5"}
                    rgb={"200/16/46"}
                  />
                  <C_ColorSwatch hex={"#000000"} cmyk={"0/0/0/100"} rgb={"0/0/0"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerPremier;
