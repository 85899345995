import { createRoot } from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter.jsx";
import App from "./App.jsx";
import ErrorBoundary from "./ErrorBoundary.jsx";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <AppRouter>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </AppRouter>,
  // </React.StrictMode>,
);
