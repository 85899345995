import React from "react";
import C_ColorSwatch from "../../../../2_components/colorSwatch/C_ColorSwatch";

let svgLogoVR1 = "https://www.kingstonloda.com/AssetLink/5p8m0j1265801ecejk1yydj8v7xgfx42.svg";
let svgLogoVR2 = "https://www.kingstonloda.com/AssetLink/o07t2lircs6himrsbi1w4aw7bjb8njx4.svg";
let svgLogoVR3 = "https://www.kingstonloda.com/AssetLink/80htnm62qyfskn1x7c3tb6ds610guj2k.svg";
let svgLogoVR4 = "https://www.kingstonloda.com/AssetLink/vdp2t0l3f6022r2mj8acf22nvsk6jylg.svg";
let svgLogoVR5 = "https://www.kingstonloda.com/AssetLink/rn5adur4542u4co2g2tyy1l6i0sgg4qk.svg";
let svgLogoVR6 = "https://www.kingstonloda.com/AssetLink/16h2dxpt524u0166b6a10fdi64i1t1mt.svg";

const ValueRam = () => {
  return (
    <>
      <div className="s-content">
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>ValueRAM Logo</h2>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The <strong>ValueRAM®</strong> logo should always be used in proportion and should
                not be changed or altered in any way. Kingston ValueRAM logo has two variations:
              </p>
              <ul>
                <li>
                  <strong>Full color ValueRAM Logo</strong>
                </li>
                <li>
                  <strong>Monochromatic ValueRAM Logo</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Full color - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={svgLogoVR1} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Full color - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={svgLogoVR2} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Monochromatic - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={svgLogoVR3} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Monochromatic - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={svgLogoVR4} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p>
                    <small>Monochromatic - over white</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "white",
                    }}
                  >
                    <img src={svgLogoVR5} alt="Kingston Care Logo" />
                  </div>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p>
                    <small>Monochromatic - over black</small>
                  </p>
                  <div
                    style={{
                      padding: "3em 5.5em",
                      border: "1px solid black",
                      background: "black",
                    }}
                  >
                    <img src={svgLogoVR6} alt="Kingston Care" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "2em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch
                    pms={"186C"}
                    hex={"#C8102E"}
                    cmyk={"0/100/80/5"}
                    rgb={"200/16/46"}
                  />
                  <C_ColorSwatch hex={"#FDB94D"} cmyk={"0/30/80/0"} rgb={"253/185/77"} />
                  <C_ColorSwatch hex={"#000000"} cmyk={"0/0/0/100"} rgb={"0/0/0"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValueRam;
