import React from "react";

import C_LandingLarge from "./C_LandingLarge";
import C_LandingMedium from "./C_LandingMedium";

let brandImg = "/images/kingston-logo-blkText.svg";
// let webImg = "/images/fpo/homepage/web-guidelines.png";
// let uxuiImg = "/images/fpo/homepage/uxui-guide.png";
// let iconImg = "/images/fpo/homepage/icon-guide.png";
// let landingImg = "/images/fpo/homepage/landing-pages.png";
// let ironkeyImg = "https://www.kingstonloda.com/AssetLink/5ipatmr3m6aad81tn15r835r85g4mg2p.jpg";
let ironkeyImg = "/images/kingston-ironkey-White.png";
let furyImg = "/images/Kingston_FURY_White_transparent.png";

const S_LandingGates = () => {
  return (
    <div className="s-landingGates u-animated u-animated--delayFast u-animated--slowest a-fadeIn">
      <div className="s-landingGates__row">
        <C_LandingLarge
          Img={brandImg}
          // title="Kingston Technology"
          link={"/brand-guidelines"}
          style={{ backgroundColor: "white" }}
          imgStyle={{ maxWidth: "27vw" }}
        />
      </div>
      <div className="s-landingGates__row">
        <C_LandingMedium
          Img={furyImg}
          // title="Fury"
          link={"/brand/fury/logo"}
          style={{ backgroundColor: "#BA0C2F" }}
          imgStyle={{ maxWidth: "13vw" }}
        />
        <C_LandingMedium
          Img={ironkeyImg}
          // title="Ironkey"
          link={"/brand/product-category-logos/iron-key"}
          style={{ backgroundColor: " #005EB8" }}
          imgStyle={{ maxWidth: "16vw" }}
        />
      </div>
      <div className="s-landingGates__row">
        <C_LandingMedium
          // Img={iconImg}
          title="Icon Guide"
          link={"/icon-guide"}
          style={{ backgroundColor: "#353535" }}
        />
        <C_LandingMedium
          // Img={webImg}
          title="Web Guidelines"
          link={"/web-ui"}
          style={{ backgroundColor: "#353535" }}
        />

        <C_LandingMedium
          // Img={uxuiImg}
          title="UI Guide"
          link={"/uiux"}
          style={{ backgroundColor: "#353535" }}
        />
      </div>
    </div>
  );
};

export default S_LandingGates;
