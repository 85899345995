/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
// import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Press = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const [currentPanel, setCurrentPanel] = useState("");
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [mobileExpand, setMobileExpand] = useState(false);

  useEffect(() => {
    let pressVars = {
      ...appState,
      currentPath: "/ui/press",
      outputName: "Press", //html name
      headerName: "Press",
      tags: null,
      description: "The Press Section contains a collection of press articles within the years.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/tabs.layout.js",
      ],
      specs: [
        ["Tab Title", ["String text, no character limit but recommended two lines max"]],
        ["Listing Title", ["String text. Month abbreviation and date"]],
        ["Listing Description", ["Date and press release link, no character limit established."]],
        ["External", ["HREF string text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EdshplnlPLZCleqV0ML44N8BwAromSrz7vo8Wfrd9UWJWw?e=LOsj7M",
        comments: null,
      },
    };
    setAppState(pressVars);
    setContentOptions({
      tab1: {
        label: "Tab 1",
        checkbox: null,
        field: {
          Eng: "20xx Lorem Ipsum",
          Span: "20xx Título",
          ChS: "20xx 标题",
          Gr: "20xx Überschrift",
          Ru: "20xx Заголовок",
          Fr: "20xx Titre",
          It: "20xx Titolo",
          Pl: "20xx Tytuł",
          Port: "20xx Título",
          Viet: "20xx Cú đội đầu",
          Turk: "20xx Başlık",
          Ukrn: "20xx Заголовок",
          Thai: "20xx หัวเรื่อง",
          Kor: "20xx 표제",
          ChT: "20xx 標題",
          Jp: "20xx 見出し",
        },
      },
      tab1Title: { label: "Date Title", checkbox: null, field: "Lorem 4" },
      tab1Desc: {
        label: "Date Description",
        checkbox: null,
        field: {
          Eng: "Lorem Ipsum",
          Span: "Título",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Titre",
          It: "Titolo",
          Pl: "Tytuł",
          Port: "Título",
          Viet: "Cú đội đầu",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "หัวเรื่อง",
          Kor: "표제",
          ChT: "標題",
          Jp: "見出し",
        },
      },
      tab2: {
        label: "Tab 2",
        checkbox: null,
        field: {
          Eng: "20xx Lorem Ipsum",
          Span: "20xx Título",
          ChS: "20xx 标题",
          Gr: "20xx Überschrift",
          Ru: "20xx Заголовок",
          Fr: "20xx Titre",
          It: "20xx Titolo",
          Pl: "20xx Tytuł",
          Port: "20xx Título",
          Viet: "20xxCú đội đầu",
          Turk: "20xx Başlık",
          Ukrn: "20xx Заголовок",
          Thai: "20xx หัวเรื่อง",
          Kor: "20xx 표제",
          ChT: "20xx 標題",
          Jp: "20xx 見出し",
        },
      },
      tab2Content: {
        label: "Copy",
        checkbox: null,
        field: {
          Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper ",
          Span: "Hacía calor en ese tiempo, para mí, no sé muy bien",
          ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追",
          Gr: "Tochter; es hieß, sie sei mit Herrn Schmidn Knabe von.",
          Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат",
          Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop..",
          It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, m",
          Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca....",
          Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,",
          Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
          Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yav.",
          Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну",
          Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่",
          Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여",
          ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
          Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋",
        },
      },
      tab3: {
        label: "Tab 3",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Hacía calor",
          ChS: "那个天气很热",
          Gr: "Tochter",
          Ru: "Лорем ипсум",
          Fr: "Il faisait.",
          Pl: "Było gorąco",
          Port: "Fazia calor",
          Viet: "Thời tiết",
          Turk: "O hava sıcaktı",
          Ukrn: "У таку погоду бул",
          Thai: "อากาศช่วงนั้นร้อน",
          Kor: "국민경제의 발전을 위한",
          ChT: "那個天氣很熱，對我來說",
          Jp: "旅ロ京青利セムレ弱改フ",
        },
      },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      viewHeight: "500",
      hardcodeHeight: true,
      width: window.innerWidth - 24,
    });
    setCurrentPanel("2017");
  }, []);

  function handleToggle(faq) {
    faq == currentPanel ? setCurrentPanel("") : setCurrentPanel(faq);
  }

  if (!appState) return "...Loading Press section";
  return (
    <section className="s-press">
      <div className="l-tabView">
        <a
          href="http://www.kingston.com/"
          target="_blank"
          rel="noreferrer"
          className="l-tabView__externals"
        >
          Press Images
        </a>
        <button
          className="l-tabView__mobile"
          aria-expanded={mobileExpand}
          onClick={() => setMobileExpand(!mobileExpand)}
        >
          <span>
            {contentOptions.tab1 && contentOptions.tab1.field[contentOptions.currentLang]}
          </span>
          <svg viewBox="0 0 32 32" className="l-tabView__mobile__arrow" aria-hidden="true">
            <path d="M24 2L8 16l16 14" />
          </svg>
        </button>
        <ul className="l-tabView__tabs" role="tablist">
          <li
            className={`l-tabView__tabs__tab ${
              currentPanel == "2017" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            onClick={() => handleToggle("2017")}
          >
            {contentOptions.tab1 && contentOptions.tab1.field[contentOptions.currentLang]}
          </li>
          <li
            className={`l-tabView__tabs__tab ${
              currentPanel == "2018" ? "l-tabView__tabs__tab--active" : ""
            }`}
            role="tab"
            onClick={() => handleToggle("2018")}
          >
            {contentOptions.tab2 && contentOptions.tab2.field[contentOptions.currentLang]}
          </li>
          <li
            className="l-tabView__tabs__tab"
            role="tab"
            data-href="http://cmc.kingston.com/"
            onClick={() => window.open("http://cmc.kingston.com/", "_blank")}
          >
            {contentOptions.tab3 && contentOptions.tab3.field[contentOptions.currentLang]}
          </li>
        </ul>
        <div className="l-tabView__panels">
          <div
            className={`l-tabView__panels__panel l-inner  ${
              currentPanel == "2017" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
          >
            <ol className="c-dateList l-inner">
              <li>
                <a className="c-dateList__listing" href="#PressLink" target="_blank">
                  <div className="c-dateList__listing__title">
                    {contentOptions.tab1Title && contentOptions.tab1Title.field}
                  </div>
                  <div className="c-dateList__listing__desc">
                    {contentOptions.tab1Desc &&
                      contentOptions.tab1Desc.field[contentOptions.currentLang]}
                  </div>
                </a>
              </li>
            </ol>
          </div>
          <div
            className={`l-tabView__panels__panel l-inner  ${
              currentPanel == "2018" ? "l-tabView__panels__panel--active" : ""
            }`}
            role="tabpanel"
          >
            <p className="l-inner">
              {contentOptions.tab2Content &&
                contentOptions.tab2Content.field[contentOptions.currentLang]}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "press",
  component: S_Press,
  navtxt: "Press",
  htmlName: "Press",
  categoryType: ["internal"],
};
